import logo from './logo.svg';
import Slider from 'react-input-slider';
import { useEffect, useState, useFarm, useRef } from "react";
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import './App.css';
import Form from './Components/Form';

function App() {
  const [state, setState] = useState({x: 0, y: 0});
  const [info, setInfo] = useState([]);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleLocationClick();
  }, [loading])


  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(position)
    setLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    let GOOGLE_MAP_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyD5UIe4yEw5e5j5NnQw_YtBHNcwuOslyJw`

    fetch(GOOGLE_MAP_API_URL)
                  .then(data => data.json())
                  .then(data => {
                          console.log(data)
                          let addr = [];
                          data.results.forEach(res => addr.push(res.formatted_address))
                          setInfo(addr)
                          setInfo(data)
                          setLoading(false);
                  })
                  .finally(console.log)
                  .catch(console.log);
  }

  function error(e) {
    console.log(e)
    console.log("Unable to retrieve your location");
  }


  return (
    <div className="App">
      <body className="body">
        <div>
                <Form addrInfo={info} loc={location}/>
        </div>
              <br />
        <div>
          {/* location ? (
            <div>
                    <p>Latitude: {String(location.latitude)} Longitude: {String(location.longitude)}</p>
            </div>
          ) : null}
          {(info)}
                <br /> 
                */}
        </div>
      </body>
    </div>
  );
}

export default App;
