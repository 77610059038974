import { useEffect, useState, useRef } from "react";
import HighButton from './HighButton';
import { Grid } from 'react-loader-spinner';

function useForm({ form, additionalData, endpointUrl }) {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    if (form) {
      e.preventDefault();

      const finalFormEndpoint = endpointUrl || form.action;
      const data = Array.from(form.elements)
        .filter((input) => input.name)
        .reduce(
          (obj, input) => Object.assign(obj, { [input.name]: input.value }),
          {}
        );

      if (additionalData) {
        Object.assign(data, additionalData);
      }

      fetch(finalFormEndpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }

          return response.json();
        })
        .then(data => {
          setMessage(data);
          setStatus("success");
        })
        .catch((err) => {
          setMessage(err.toString());
          setStatus("error");
        });
    }
  };

        console.log(message)
  return { handleSubmit, status, message };
}


function Form({addrInfo, loc}) {

  const FORM_ENDPOINT = 'https://api-rrmehnvjka-uc.a.run.app/postData';
  // const FORM_ENDPOINT = 'http://127.0.0.1:5001/whoshigh/us-central1/api/postData';
  const formElement = useRef(null);
  const additionalData = {
    sent: new Date().toISOString(),
    location: loc,
    addrInfo: addrInfo,
  };

  const { handleSubmit, status, message } = useForm({
    form: formElement.current,
    additionalData,
  });

  console.log(addrInfo)
  return (
          loc ? (
            <form
                  action={FORM_ENDPOINT}
                  onSubmit={handleSubmit}
                  method="POST"
                  target="_blank"
                  ref={formElement}
                >
                  <HighButton />
                  <br />
                  {status == "success" ? (
                    `Great! You are #${message.count} in ${message.zip}`
                  ) : null}
                </form>
          ) : (
                  <Grid
  height="80"
  width="80"
  color="#1e88e5"
  ariaLabel="grid-loading"
  radius="12.5"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/>
          )
  )
}

export default Form;
