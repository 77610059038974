import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import '../App.css';

function HighButton() {
  return (
    <AwesomeButton className='highButton' type="primary">Im High</AwesomeButton>
  )
}

export default HighButton;
